
<template>
  <v-row>
    <v-col :cols="colspan[0]">
      <v-autocomplete
        :value="paymentData.paymentType"
        :items="filteredPaymentTypes"
        :rules="notRequired ? [] : [rules.required]"
        label="Typ płatności"
        placeholder="Wybierz typ płatności"
        v-bind="sharedAttrs"
        :disabled="disabled"
        @input="emitPaymentType"
      />
    </v-col>

    <v-col
      v-if="paymentDueDateVisible"
      :cols="colspan[1]"
    >
      <v-combobox
        v-model.number="paymentDueDays"
        :items="dueDaysOptions"
        :disabled="!isDueDaysAllowed || disabled"
        :rules="isDueDaysAllowed ? [rules.required, rules.number, rules.lowerThan(366)] : []"
        label="Termin przelewu"
        type="number"
        placeholder="Termin przelewu"
        @wheel="$event.target.blur()"
        v-bind="sharedAttrs"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import rules from '../../../utils/validators'

export default {
  props: {
    paymentData: {
      type: Object,
      required: true
    },
    cols: {
      type: String,
      default: '7/5'
    },
    notRequired: {
      type: Boolean,
      default: false
    },
    paymentDueDateVisible: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    courseType: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    rules,
    dueDaysOptions: [1, 3, 7, 10, 14, 21, 30],
    sharedAttrs: {
      dense: true,
      outlined: true,
      appendIcon: 'mdi-chevron-down'
    }
  }),
  computed: {
    ...mapState({
      paymentTypes: state => state.core.filters.paymentTypes,
    }),
    filteredPaymentTypes () {
      return this.paymentTypes.filter(type => this.courseType === 'Transport' ? type !== 'Gotówka przy zabraniu' : true)
    },
    paymentDueDays: {
      get () {
        return this.paymentData.paymentDueDays
      },
      set (days) {
        this.emitUpdate('payment.paymentDueDays', days)
      }
    },
    isDueDaysAllowed () {
      return this.paymentData.paymentType === 'Przelew'
    },
    colspan () {
      return this.paymentDueDateVisible ? this.cols?.split('/') || [6, 6] : [12, 0]
    }
  },
  methods: {
    emitUpdate (key, value) {
      this.$emit('update', { key, value })
    },
    emitPaymentType (type) {
      if (this.isDueDaysAllowed && type !== 'Przelew') {
        this.emitUpdate('payment.paymentDueDays', null)
      } else if (type === 'Przelew') {
        this.emitUpdate('payment.paymentDueDays', 7)
      }
      this.emitUpdate('payment.paymentType', type)
    }
  }
}
</script>
